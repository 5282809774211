import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/plumbing/Head'
import CTA from '../components/Mailchimp'

const Contact = () => (
  <Layout>
    <Seo title="Contact" description="Contact us" />
    <section className="ten-central" style={{ marginTop: '8rem' }}>
      <div className="ten-central">
        <h1>Contact us</h1>
        <p>Let us know what you’re thinking. We want to hear from you.</p>
        <p>Connect with us for a live demo and tell us about a project you have in mind. </p>
        <p>Get in touch. Together we'll make it an augmented reality.</p>
        <p><a href="mailto:human@mythologi.es">human@mythologi.es</a></p>
        <CTA />
      </div>
    </section>
  </Layout>
)

export default Contact
